@import "./variables.module.scss";

.pane {
  flex-shrink: 0;
  position: relative;
  height: 100%;
  position: sticky;
  overflow: hidden;
  background: $colorWhite;
}

.pane+.pane {
  border-left: 1px solid $colorGray;
}

.paneBackground,
.paneForeground {
  position: absolute;
  width: 100%;
  height: 100%;
}

.paneBackground {
  transition: opacity ease-in $transitionDuration;
}

.paneForeground {
  padding: $paneStickyWidth;
  overflow-x: hidden;
  overflow-y: auto;
}

.paneTitle {
  writing-mode: vertical-lr;
  padding: 24px 0;
  font-size: 1rem;
  line-height: $paneStickyWidth;
}