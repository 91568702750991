@import "./variables.module.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  font-size: $fontSize;
  line-height: $lineHeight;
  color: $colorText;
  background: $colorGray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  background: transparent;
  color: $colorPrimary;
  text-decoration: none;

  &:hover {
    background: lighten($colorPrimary, 45%);
    border-radius: $radiusS;
    padding: 2px 4px;
    margin: -2px -4px;
  }
}

ul,
ol {
  padding-left: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 500;
  color: $colorBlack;
}

h1 {
  font-size: 2rem;
  margin-bottom: 3rem;
}

h2 {
  font-size: 1.5rem;
}

* + h2,
h3,
h4,
h5,
h6 {
  margin-top: 2rem;
}

blockquote,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p,
pre,
table,
img,
video {
  margin-bottom: 1rem;
}
